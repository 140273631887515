import React from "react"
import styled from "styled-components"
import SearchIcon from "../Icons/SearchIcon"

const SearchForm = styled.div`
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  form {
    margin-bottom: 0;
  }
  .input-icon {
    > .form-control {
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid #dce7ef;
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }
    .icon {
      width: 45px;
      > svg {
        fill: #062c44;
        width: 20px;
      }
    }
  }
`

const SearchBox = ({ setQuery, searchKey }) => {
  const handleChange = e => {
    e.preventDefault()
    setQuery(e.target.value)
  }
  const handleSubmit = e => {
    e.preventDefault()
  }

  return (
    <SearchForm className="search-form">
      <form onSubmit={e => handleSubmit(e)}>
        <div className="input-icon right">
          <input
            className="form-control"
            type="text"
            placeholder="Search..."
            id="search"
            name="search"
            autoComplete="off"
            onChange={e => handleChange(e)}
            value={searchKey}
          />
          <span className="icon">
            <SearchIcon />
          </span>
        </div>
      </form>
    </SearchForm>
  )
}

export default SearchBox
