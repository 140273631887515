import React from 'react';
import { Link } from 'gatsby';
import Img from "gatsby-image"
import { MediaCard, MediaCardThumb, MediaCardBody, MediaCardTitle, MediaCardDesc } from "../MediaStyle";
import { getProductUrl } from '../../utils/product';


const SearchCard = ({ data }) => {
  const productUrl = getProductUrl(data);

  const renderImage = () => {
    if (data.image) {
      return (
        <Img fluid={data.image.fluid} alt={data.title} />
      )
    }

    if (data.imageSrc) {
      return (
        <img src={data.imageSrc} alt={data.title} />
      )
    }

    return null;
  }

  return (
    
    <MediaCard className='media-card' >
      <MediaCardThumb className='media-card-thumb'>
        {renderImage()}
      </MediaCardThumb>
      <MediaCardBody className='media-card-body'>
        <MediaCardTitle className='media-card-title'><Link to={`${productUrl}`}>{data.title}</Link></MediaCardTitle>
        <MediaCardDesc><p>{data.productDesc}</p></MediaCardDesc>
      </MediaCardBody>
    </MediaCard>
  )
}

export default SearchCard