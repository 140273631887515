import styled from 'styled-components'

export const MediaCard = styled.div`
	
	margin-bottom:20px;
	@media (min-width: 768px) {
		margin-bottom:30px;
	}
`
export const MediaCardBody = styled.div`
	background-color:transparent;
    position: relative;
`
export const MediaCardThumb = styled.div`
    overflow:hidden;
    position:relative;
`

export const MediaCardTitle = styled.div`
	font-family: "Chakra Petch", sans-serif;
	font-weight: 700;
	margin-bottom:5px;
	font-size: 20px;
	line-height: 34px;
    > a{
        color:#062C44;
        &:hover, &:focus, &:active{
            color:#000;
        }
    }
	@media (min-width: 992px) {
		font-size: 24px;
		line-height: 36px;
	}
	@media (min-width: 1200px) {
		font-size: 32px;
		line-height: 40px;
	}
`
export const MediaCardDesc = styled.div`
  color:#000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  p {	
	font-size: 14px;
	line-height: 24px;
	margin-bottom:15px;
	@media(min-width:1200px){        
	font-size: 16px;
	line-height: 28px;
	margin-bottom:20px;
	}    
  }
`