import React, { useEffect, useState } from "react"
import { PageItem, PageList, Pages } from "../ToolbarStyle"
import styled from "styled-components"
import SearchCard from "../SearchCard"

const SearchItem = styled.div`
  .media-card {
    display: flex;
	  flex-wrap:wrap;
    .media-card-thumb {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding: 0 10px;
      @media (min-width: 768px) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      @media (min-width: 1200px) {
        padding: 0 15px;
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
    .media-card-body {
      width: 100%;
      min-height: 1px;
      padding: 10px 10px 0 10px;
      @media (min-width: 768px) {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      @media (min-width: 1200px) {
        padding: 0 15px;
        flex: 0 0 70%;
        max-width: 70%;
      }
    }
  }
`

const SearchResult = ({ items }) => {
  const [pageno, setPageno] = useState(1)
  const pageSize = 10

  useEffect(() => {
    setPageno(1)
  }, [items])

  const numberOfPages = Math.ceil(items.length / pageSize)
  const startIndex = (pageno - 1) * pageSize
  const endIndex = startIndex + pageSize
  const pageItems = items.slice(startIndex, endIndex)

  let beforeDot = 0
  let afterDot = 0

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [pageno])

  return (
    <div className="search-list">
      {pageItems.map((item, i) => (
        <SearchItem key={i}>
          <SearchCard data={item} />
        </SearchItem>
      ))}
      <div className="pagination" align="center">
        <Pages className="pages" align="center">
          <PageList
            role="navigation"
            aria-label="Pagination"
            style={{ justifyContent: "center" }}
          >
            {pageno > 1 && (
              <PageItem
                className="page next"
                onClick={() => setPageno(pageno - 1)}
              >
                <span className="selectable-page">&lt;</span>
              </PageItem>
            )}
            {Array.from({ length: numberOfPages }).map((_, i) => {
              if (i + 1 > 1 && i < pageno - 2) {
                beforeDot = beforeDot + 1
                if (beforeDot === 1) {
                  return <span key={i}>...</span>
                } else {
                  return <span key={i} />
                }
              } else if (i + 1 > pageno + 1 && i + 1 < numberOfPages) {
                afterDot = afterDot + 1
                if (afterDot === 1) {
                  return <span key={i}>...</span>
                } else {
                  return <span key={i} />
                }
              } else {
                return (
                  <PageItem
                    className="page active"
                    onClick={() => setPageno(i + 1)}
                    key={i}
                  >
                    {pageno === i + 1 ? (
                      <span className="active-pageno">{i + 1}</span>
                    ) : (
                      <span className="selectable-pageno">{i + 1}</span>
                    )}
                  </PageItem>
                )
              }
            })}
            {pageno < numberOfPages && (
              <PageItem
                className="page next"
                onClick={() => setPageno(pageno + 1)}
              >
                <span className="selectable-page">&gt;</span>
              </PageItem>
            )}
          </PageList>
        </Pages>
      </div>
    </div>
  )
}

export default SearchResult
